<mat-card class="results-container">
    <mat-card-header>
        <mat-card-title>Toetsresultaten {{ examName$ | async }}</mat-card-title>
        <mat-card-subtitle>{{(examInstance$ | async)?.customTitle }}</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
        <ng-container *ngIf="(examResults$ | async) as examResults">
            <div class="stats" *ngIf="examResults.results.length > 0; else noResult">
                <p>Gemiddelde score: {{ examResults.averageScorePercentage | number:'1.0-0' }}%</p>
                <p>Totaal gemaakt: {{ examResults.completedCount }}</p>
            </div>
            <ng-template #noResult>
                <div class="stats">
                    <p>Nog geen toetsen gemaakt</p>
                </div>
            </ng-template>
            <mat-table *ngIf="examResults.results.length > 0" [dataSource]="dataSource$ | async"
                class="mat-elevation-z8">
                <ng-container *ngFor="let column of (displayedColumns$ | async)">
                    <ng-container [matColumnDef]="column.key">
                        <mat-header-cell *matHeaderCellDef
                            [ngClass]="{'text-center': column.align === 'center', 'text-left': column.align === 'left', 'text-right': column.align === 'right'}">
                            <span [attr.title]="column.title">{{ column.text }}</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"
                            [ngClass]="{'text-center': column.align === 'center', 'text-left': column.align === 'left', 'text-right': column.align === 'right'}">
                            <ng-container [ngSwitch]="column.type">
                                <ng-container *ngSwitchCase="'user'">
                                    <div class="pointer" (click)="onStudentClick(element)">
                                        {{ element[column.key] | username | async }}
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'score'">
                                    <ng-container *ngIf="column.key === 'totalScore'; else interactionScore">
                                        <ng-container *ngIf="examResults.hideGrade; else showGrade">
                                            {{ element.scorePercentage | number:'1.0-0' }}%
                                        </ng-container>
                                        <ng-template #showGrade>
                                            {{ element.grade | number:'1.0-1' }}
                                        </ng-template>
                                    </ng-container>
                                    <ng-template #interactionScore>
                                        <span class="interaction-icon"
                                            (click)="navigateToPath(element.examSessionId,+column.key)">
                                            <mat-icon *ngIf="element.interactionScores[+column.key] === 3"
                                                class="correct-answer">check</mat-icon>
                                            <mat-icon *ngIf="element.interactionScores[+column.key] === 1"
                                                class="incorrect-answer">close</mat-icon>
                                            <mat-icon *ngIf="element.interactionScores[+column.key] === 0"
                                                class="nottried-answer">texture</mat-icon> </span>
                                    </ng-template>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    {{ element[column.key] }}
                                </ng-container>
                            </ng-container>
                        </mat-cell>
                    </ng-container>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumnKeys$ | async"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnKeys$ | async;"></mat-row>
            </mat-table>
        </ng-container>
    </mat-card-content>

    <mat-card-actions align="end">
        <app-button-with-spinner icon="download" (click)="downloadExcel()" [isLoading]="isExcelDownloading$"
            [hidden]="(isDataReady$ | async) !== true">
            Excel
        </app-button-with-spinner>
        <button mat-raised-button color="primary" (click)="close()">Terug</button>
    </mat-card-actions>
</mat-card>